import { ChangeDetectionStrategy, Component } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'

import { IconEmailClosedEMailAddress } from '@alliance/shared/icons'
import { Environment } from '@alliance/shared/environment'
import { TranslationService } from '@alliance/shared/translation'

import { Translations } from '../../../localization/translations'

@Component({
  selector: 'alliance-clubs-footer-base-component',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterBaseComponent {
  public emailControl = new FormControl(null, [Validators.required, Validators.email])
  public subscribeForSeeker = new FormControl(true)
  public subscribeForEmployer = new FormControl(true)
  public readonly IconEmailClosedEMailAddress = IconEmailClosedEMailAddress.name
  public mainSiteUrl = this.environment.mainSiteUrl

  public content: Array<{
    title: string
    showItemsInMobile: boolean
    items: Array<{ label: string; isInternal?: boolean; url: string }>
  }> = [
    {
      title: this.translationService.translate(this.translations.about.title),
      showItemsInMobile: false,
      items: [
        {
          label: this.translationService.translate(this.translations.about.project),
          isInternal: true,
          url: '/about-budni'
        },
        {
          label: this.translationService.translate(this.translations.about.mediakit),
          url: 'https://mediakit.rabota.ua/'
        },
        {
          label: this.translationService.translate(this.translations.about.privacy),
          url: 'https://images.cf-rabota.com.ua/alliance/privacy_offer.pdf'
        }
      ]
    },
    {
      title: this.translationService.translate(this.translations.products.title),
      showItemsInMobile: false,
      items: [
        {
          label: this.translationService.translate(this.translations.products.seeker),
          url: this.environment.mainSiteUrl ?? ''
        },
        {
          label: this.translationService.translate(this.translations.products.employer),
          url: `${this.environment.mainSiteUrl ?? ''}employer`
        }
      ]
    },
    {
      title: this.translationService.translate(this.translations.roadToVictory.title),
      showItemsInMobile: false,
      items: [
        {
          label: this.translationService.translate(this.translations.roadToVictory.posts.first),
          isInternal: true,
          url: '/career/yak-dopomohty-ukrayinskiy-armiyi-usi-mozhlyvi-varianty-ta-sposoby'
        },
        {
          label: this.translationService.translate(this.translations.roadToVictory.posts.second),
          isInternal: true,
          url: '/resume-job-search/robota-ua-zapustyla-telegram-bot-dlya-poshuku-aktualnyh-vakansiy'
        },
        {
          label: this.translationService.translate(this.translations.roadToVictory.posts.fourth),
          isInternal: true,
          url: '/career/yak-nadavaty-pershu-medychnu-dopomohu-alhorytm-diy-pry-riznyh-vydah-ushkodzhen'
        }
      ]
    }
  ]

  public constructor(public translations: Translations, private environment: Environment, private translationService: TranslationService) {}

  public trackByFn(index: number): number {
    return index
  }
}
