import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { AbstractSeoDictionaryService, SeoParamsResponseModel } from '@alliance/shared/seo'
import { BudniSeoParams } from '../models/budni-seo-params'
import { BudniSeoKeysEnum } from '../models/budni-seo-keys.enum'
import { BudniPageDictionaryService } from '../dictionaries/budni-page-dictionary.service'
import { BudniPostPageDictionaryService } from '../dictionaries/budni-post-page-dictionary.service'
import { BudniRubricsPageDictionaryService } from '../dictionaries/budni-rubrics-page-dictionary.service'
import { BudniTagsPageDictionaryService } from '../dictionaries/budni-tags-page-dictionary.service'

@Injectable({
  providedIn: 'root'
})
export class BudniDictionaryService extends AbstractSeoDictionaryService<BudniSeoParams> {
  public constructor(
    private budniPageDictionaryService: BudniPageDictionaryService,
    private budniPostPageDictionaryService: BudniPostPageDictionaryService,
    private budniRubricsPageDictionaryService: BudniRubricsPageDictionaryService,
    private budniTagsPageDictionaryService: BudniTagsPageDictionaryService
  ) {
    super()
  }

  public getSeoParams$(params: BudniSeoParams): Observable<SeoParamsResponseModel> {
    return of(this.getSeoParamsByKey(params))
  }

  private getSeoParamsByKey(params: BudniSeoParams): SeoParamsResponseModel {
    switch (params.key) {
      case BudniSeoKeysEnum.budni:
        return this.budniPageDictionaryService.getSeoParams()
      case BudniSeoKeysEnum.budniPostPage:
        return this.budniPostPageDictionaryService.getSeoParams(params?.params)
      case BudniSeoKeysEnum.budniRubricsPage:
        return this.budniRubricsPageDictionaryService.getSeoParams(params?.params)
      case BudniSeoKeysEnum.budniTagsPage:
        return this.budniTagsPageDictionaryService.getSeoParams(params?.params)
      default:
        return { ...this.DEFAULT_RESPONSE_SEO_PARAMS }
    }
  }
}
