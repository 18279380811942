import { IconModel } from '@alliance/shared/icons'
import { RxStateComponent } from '@alliance/shared/models'
import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core'
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus'

@Component({
  selector: 'santa-skeleton-loader',
  templateUrl: './santa-skeleton-loader.component.html',
  styleUrls: ['./santa-skeleton-loader.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SantaSkeletonLoaderComponent extends RxStateComponent<{
  loading: boolean
  empty: boolean
  first: boolean
  skeleton: IconModel | null
}> {
  @Input() public loadingFirst = false
  @Input() public showProgressBar = true
  @Input() public useClasses = ''
  @Input() public skeletonWidth: number | undefined

  @ContentChild('content') public content: PolymorpheusContent | undefined
  @ContentChild('empty') public emptyContent: PolymorpheusContent | undefined

  public state = this.connectViewState(['loading', 'empty', 'first', 'skeleton'])

  public constructor() {
    super()

    this.initState({
      loading: true,
      empty: false,
      first: true,
      skeleton: null
    })
  }

  @Input()
  public set loading(loading: boolean) {
    if (!loading && this.get().first) {
      this.set({ first: false })
    }
    this.set({ loading })
  }

  @Input()
  public set empty(empty: boolean) {
    if (empty) {
      this.set({ first: true })
    }
    this.set({ empty })
  }

  @Input()
  public set skeleton(skeleton: IconModel) {
    this.set({ skeleton })
  }

  public setLoading(loading: boolean): void {
    this.set({ loading })
  }

  public setIsEmpty(empty: boolean): void {
    this.set({ empty })
  }

  public prepareContext(): { $implicit: (value: boolean) => void; empty: (value: boolean) => void } {
    return {
      $implicit: this.setLoading.bind(this),
      empty: this.setIsEmpty.bind(this)
    }
  }
}
