import { log } from '@alliance/shared/logger'
import { commit } from '@alliance/shared/utils'

import { enableProdMode, isDevMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppBrowserModule } from './app/app.browser.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

if (!isDevMode()) {
  console.info('commit: ', commit)
}

/* eslint-disable */
document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch(err => log.error({ where: 'clubs', category: 'try_catch', message: 'bootstrapModule failed', error: err }))
})
