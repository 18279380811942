import { ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnDestroy, Renderer2, ViewContainerRef, ɵcreateInjector as createInjector } from '@angular/core'
import { BehaviorSubject, take } from 'rxjs'

import { HeaderData, HeaderHelperService } from '@alliance/shared/header/utils'

@Component({
  selector: 'alliance-clubs-shell-header',
  template: '',
  styleUrls: ['./shell.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellComponent implements OnDestroy {
  private _headerData$ = new BehaviorSubject<HeaderData | null>(null)

  public constructor(private vcRef: ViewContainerRef, private _injector: Injector, private elRef: ElementRef, private renderer: Renderer2, private headerHelperService: HeaderHelperService) {
    this.headerHelperService
      .headerOverlayCreated$()
      .pipe(take(1))
      .subscribe(() => {
        this.renderer.setStyle(this.elRef.nativeElement, 'height', 'auto')
      })

    import('../lazy-header/lazy-header.module').then(({ LazyHeaderModule }) => {
      const injector = createInjector(LazyHeaderModule, this._injector)
      const module = injector.get(LazyHeaderModule)
      const component = module.render(this.vcRef)
      component.instance.connect('headerData', this._headerData$)
    })
  }
  @Input()
  public set headerData(headerData: HeaderData | null) {
    this._headerData$.next(headerData)
  }

  public ngOnDestroy(): void {
    this._headerData$.complete()
  }
}
