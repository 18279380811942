import { Injectable } from '@angular/core'
import { TranslationService } from '@alliance/shared/translation'
import { Translations } from '../localization/translations'
import { HelpersService } from '@alliance/shared/seo'
import { Environment } from '@alliance/shared/environment'

@Injectable({
  providedIn: 'root'
})
export class BudniBaseDictionaryService {
  public constructor(public translations: Translations, public translationService: TranslationService, public helpersService: HelpersService, private environment: Environment) {}

  public getBaseUrl(): string {
    return this.environment.budni ?? ''
  }
}
