import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { InitialsPipe } from './pipes/initials.pipe'
import { ShortDatePipe } from './pipes/short-date.pipe'

@NgModule({
  imports: [CommonModule],
  declarations: [InitialsPipe, ShortDatePipe],
  exports: [InitialsPipe, ShortDatePipe]
})
export class ClubsUtilsModule {}
