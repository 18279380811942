import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { filter, Observable, tap } from 'rxjs'

export type Extensions = {
  errors?: Array<{ extensions?: { code: string } }>
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private ERRORS = ['NULL_REFERENCE', 'FORMAT', 'NOT_FOUND']
  public constructor(private router: Router) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      filter((evt): evt is HttpResponse<Extensions> => !!evt),
      tap((evt: HttpResponse<Extensions>) => {
        if (evt.body && evt.body.errors && evt.body.errors[0]) {
          if (this.ERRORS.includes(evt.body?.errors[0].extensions?.code ?? '')) {
            this.router
              .navigate(['/404'], {
                skipLocationChange: true
              })
              .then(null)
          }
        }
      })
    )
  }
}
