<div class="footer">
  <!-- MAIN CONTENT -->
  <div class="footer-main-content">
    <!--  ABOUT, PRODUCTS, ROAD_TO_VICTORY  -->
    <div *ngFor="let cnt of content; let first = first; trackBy: trackByFn; let i = index" [class.santa-mt-20]="!first">
      <p class="footer-ul-title" (click)="toggleItemsVisibility(i)">
        {{ cnt.title }}

        <alliance-icon
          class="santa-transform santa-text-red-500"
          [name]="IconGrayArrowsVerticalDropdown"
          [inheritColor]="true"
          [ngClass]="cnt.showItemsInMobile ? 'santa--rotate-90' : 'santa-rotate-90'"
        ></alliance-icon>
      </p>

      <ul class="footer-ul" *ngIf="cnt.showItemsInMobile">
        <li *ngFor="let item of cnt.items; trackBy: trackByFn">
          <alliance-clubs-links [link]="item.url" [isInternal]="!!item.isInternal">
            {{ item.label }}
          </alliance-clubs-links>
        </li>
      </ul>
    </div>

    <!-- TODO: UNCOMMENTED WHEN SUBSCRIBE IS DONE -->
    <!--  <div class="footer-main-content">-->
    <!--    &lt;!&ndash;  EMAIL SUBSCRIBE  &ndash;&gt;-->
    <!--    <div class="footer-email-subscribe">-->
    <!--      <p class="santa-typo-secondary-bold santa-mb-20">{{ translations.subscribe.title | translate }}</p>-->

    <!--      <div class="santa-bg-white">-->
    <!--        <santa-input-->
    <!--          label="{{ translations.subscribe.enterEmail | translate }}"-->
    <!--          [formControl]="emailControl"-->
    <!--          [type]="'email'"-->
    <!--          [autocomplete]="'email'"-->
    <!--        ></santa-input>-->
    <!--      </div>-->

    <!--      <div class="santa-flex santa-flex-row santa-my-20">-->
    <!--        <santa-checkbox [formControl]="subscribeForSeeker" class="santa-typo-additional">{{-->
    <!--          translations.subscribe.forSeeker | translate-->
    <!--        }}</santa-checkbox>-->
    <!--        <santa-checkbox [formControl]="subscribeForEmployer" class="santa-typo-additional santa-ml-20">-->
    <!--          {{ translations.subscribe.forEmployer | translate }}-->
    <!--        </santa-checkbox>-->
    <!--      </div>-->

    <!--      <div>-->
    <!--        <santa-button [fullWidth]="true">-->
    <!--          <alliance-icon [name]="IconEmailClosedEMailAddress" [inheritColor]="true"></alliance-icon>-->
    <!--          <span class="santa-ml-10 santa-typo-regular-bold">{{ translations.subscribe.buttonLabel | translate }}</span>-->
    <!--        </santa-button>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->

    <!-- BOTTOM CONTENT -->
    <div class="footer-bottom-content">
      <!--   TODO: FONT STYLES   -->
      <div class="santa-flex santa-flex-col santa-mb-20 santa-w-full" style="font-size: 8px">
        <p class="santa-mb-10">{{ translations.terms.title | translate }}</p>
        <p>{{ translations.terms.text | translate }}</p>
      </div>

      <div class="santa-flex santa-justify-between">
        <!--  BUDNI LOGO  -->
        <div class="santa-flex santa-flex-col">
          <div class="santa-flex">
            <alliance-clubs-logo></alliance-clubs-logo>
          </div>
          <!--   TODO: FONT STYLES   -->
          <p class="santa-mt-10" style="font-size: 8px">{{ translations.budniDescription | translate }}</p>
        </div>

        <!--  ROBOTA LOGO  -->
        <div class="santa-flex santa-flex-col">
          <a [href]="mainSiteUrl" target="_blank" class="santa-flex santa-items-center">
            <img height="24" width="auto" loading="lazy" src="https://images.cf-rabota.com.ua/2017/12/logos/logo-symbol-red.svg" alt="robota.ua" />
            <img class="santa-pl-10" height="12" width="auto" loading="lazy" src="https://images.cf-rabota.com.ua/2016/11/logo-text-none-padding.svg" alt="robota.ua" />
          </a>
          <!--   TODO: FONT STYLES   -->
          <p class="santa-mt-10" style="font-size: 8px">{{ translations.robotaDescription | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
