import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { LogoComponent } from './components/logo.component'

import { SharedIconsModule } from '@alliance/shared/icons'
import { SharedTranslationModule } from '@alliance/shared/translation'

@NgModule({
  imports: [CommonModule, SharedIconsModule, SharedTranslationModule, RouterModule],
  declarations: [LogoComponent],
  exports: [LogoComponent]
})
export class ClubsUiLogoModule {}
