export default {
  budni: {
    title: `Budni - онлайн-журнал сайту robota.ua про пошук роботи, кар'єру та управління персоналом.`,
    description:
      "Поради та думки експертів, інтерв'ю з професіоналами, історії українців. Рецензії на книги та афіша цікавих подій, експертні матеріали у сфері HR, управлінні персоналом, бренди роботодавця та кар'єрі.",
    h1: `Budni - онлайн-журнал сайту robota.ua про пошук роботи, кар'єру та управління персоналом.`,
    breadcrumbs: {
      home: 'Головна'
    },
    rubricPageTitle: '{rubricName} ➣ Budni - онлайн-журнал сайту robota.ua про пошук роботи',
    rubricPageDescription:
      "Онлайн-журнал Budni від robota.ua ➣ {rubricName} - Поради та думки експертів, інтерв'ю з професіоналами, історії українців, рецензії на книги та афіша цікавих подій, експертні матеріали у сфері HR, управлінні персоналом, бренди роботодавця та кар'єрі.",
    tagPageTitle: '{tagName} ➣ Budni - онлайн-журнал сайту robota.ua',
    tagPageDescription:
      "Онлайн-журнал Budni від robota.ua ➣ {tagName} - Поради та думки експертів, інтерв'ю з професіоналами, історії українців, рецензії на книги та афіша цікавих подій, експертні матеріали у сфері HR, управлінні персоналом, бренди роботодавця та кар'єрі."
  }
}
