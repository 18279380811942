import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { CookieStorage } from '@alliance/shared/storage'
import { Inject, Injectable, Optional } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { Request } from 'express'
import { decodeToken } from './decode-token'
import { DetectPlatformService } from './detect-platform.service'

@Injectable({
  providedIn: 'root'
})
export class GetTokenFromCookiesService {
  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() @Inject(REQUEST) private readonly request: Request,
    private cookieStorage: CookieStorage,
    private environment: Environment,
    private platform: DetectPlatformService
  ) {}

  public tokenFromCookie(cookieKey: string): string | null {
    if (this.platform.isServer) {
      return null
    }

    try {
      const isNotProd = this.environment.domain !== 'rabota.ua' && this.environment.domain !== 'robota.ua'

      // CookieStorage (with CookieService) can't get multiple cookies with a same name,
      // so have to use another way for getting cookies for dev & test env-s
      let plainCookies = ''
      try {
        plainCookies = this.request?.headers?.cookie || this.document?.cookie || ''
      } catch (e) {
        log.warn({ where: 'shared-utils: GetTokenFromCookiesService', category: 'try_catch', message: 'tokenFromCookie failed', error: e })
      }

      const token = isNotProd ? this.getDevToken(plainCookies) : this.cookieStorage.getItem(cookieKey) || null

      return token || null
    } catch (e) {
      log.warn({ where: 'shared-utils: GetTokenFromCookiesService', category: 'try_catch', message: 'tokenFromCookie failed', error: e })
      return null
    }
  }

  private getDevToken(plainCookies: string): string | null {
    const allJwtTokens: string[] =
      plainCookies
        .split(';')
        .filter(value => value.includes('jwt-token='))
        .map(value => value.replace('jwt-token=', '').trim()) || []
    // todo remove replace after migration rabota to robota
    return allJwtTokens.filter(_token => decodeToken<{ aud?: string }>(_token)?.aud === this.environment.domain?.replace('robota', 'rabota'))?.pop() || null
  }
}
