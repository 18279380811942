import { SharedIconsModule } from '@alliance/shared/icons'
import { SharedSantaModule } from '@alliance/shared/santa'

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus'

import { SantaSkeletonLoaderComponent } from './santa-skeleton-loader.component'
import { IndeterminateProgressBarComponent } from './indeterminate-progress-bar/indeterminate-progress-bar.component'

@NgModule({
  imports: [CommonModule, SharedSantaModule, MatProgressBarModule, PolymorpheusModule, SharedIconsModule],
  declarations: [SantaSkeletonLoaderComponent, IndeterminateProgressBarComponent],
  exports: [SantaSkeletonLoaderComponent]
})
export class SantaSkeletonLoaderModule {}
