import { Injectable } from '@angular/core'
import { BreadcrumbList, Organization } from 'schema-dts'
import { SeoParamsResponseModel } from '@alliance/shared/seo'
import { BudniPostPageModel } from '../models/budni-post-page.model'
import { BudniBaseDictionaryService } from './budni-base-dictionary.service'

@Injectable({
  providedIn: 'root'
})
export class BudniPostPageDictionaryService extends BudniBaseDictionaryService {
  public getSeoParams(params?: BudniPostPageModel): SeoParamsResponseModel {
    return {
      title: params?.headline ?? '',
      description: params?.description ?? this.translationService.translate(this.translations.budni.description),
      h1: [params?.headline ?? ''],
      canonicalUrl: this.helpersService.createURL(this.getBaseUrl(), `${params?.rubricEngname ?? ''}/${params?.postSlug ?? ''}`),
      hrefLang: [],
      alternateUrl: '',
      noIndexNoFollow: false,
      jsonLd: this.getJsonLd(params)
    }
  }

  private getJsonLdBlogPosting(params?: BudniPostPageModel): string {
    return this.helpersService.createJsonLd<Organization>({
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': this.helpersService.createURL(this.getBaseUrl(), `${params?.rubricEngname ?? ''}/${params?.postSlug ?? ''}`)
      },
      headline: params?.headline ?? '',
      image: params?.image ?? '',
      author: {
        '@type': 'Person',
        name: params?.authorName ?? ''
      },
      datePublished: params?.datePublished ?? ''
    })
  }

  private getJsonLdBreadcrumbs(params?: BudniPostPageModel): string {
    return this.helpersService.createJsonLd<BreadcrumbList>({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: this.translationService.translate(this.translations.budni.breadcrumbs.home),
          item: this.helpersService.createURL(this.getBaseUrl())
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: params?.rubricName ?? '',
          item: this.helpersService.createURL(this.getBaseUrl(), params?.rubricEngname ?? '')
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: params?.headline ?? '',
          item: this.helpersService.createURL(this.getBaseUrl(), `${params?.rubricEngname ?? ''}/${params?.postSlug ?? ''}`)
        }
      ]
    })
  }

  private getJsonLd(params?: BudniPostPageModel): string {
    return this.getJsonLdBlogPosting(params) + this.getJsonLdBreadcrumbs(params)
  }
}
