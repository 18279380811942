import { Injectable } from '@angular/core'
import { Thing, WithContext } from 'schema-dts'
import { FullHrefLangModel, SeoParamsResponseHrefLangModel } from '../models/seo-params-response-href-lang.model'
import { IsNewRobotaDomainService } from '@alliance/shared/utils'
import { LanguageCodesEnum } from '@alliance/shared/translation'
import { PlatformRelation } from '../models/platform-relation'

@Injectable({ providedIn: 'root' })
export class HelpersService {
  public constructor(private isNewRobotaDomainService: IsNewRobotaDomainService) {}

  public createJsonLd<T extends Thing>(data: WithContext<T> | Array<WithContext<T>> | object = {}): string {
    return JSON.stringify([...(Array.isArray(data) ? data : [data])])
  }

  public createHrefLangURLs(envHostUrl: string, urlForMobile?: string, urlForDesktop?: string): FullHrefLangModel[] {
    return [LanguageCodesEnum.UK, LanguageCodesEnum.RU].map(langCode => {
      const correctLangCode = this.getCorrectLocale(langCode)
      return {
        langCode,
        mobileUrl: urlForMobile ? this.createURL(envHostUrl, urlForMobile, correctLangCode) : '',
        desktopUrl: urlForDesktop ? this.createURL(envHostUrl, urlForDesktop, correctLangCode) : ''
      }
    })
  }
  public createURL(envHostUrl: string, path = '', langCode: LanguageCodesEnum | null | string = null): string {
    return new URL((langCode ?? '') + '/' + path.replace(/^\/+/g, '' /* remove leading slash */), envHostUrl).toString().replace(/\/$/, '' /* remove trailing slash */)
  }

  public getAdaptedHrefLangParams(hrefLang: FullHrefLangModel[], relation: PlatformRelation = PlatformRelation.desktop): SeoParamsResponseHrefLangModel[] {
    return hrefLang.map(({ langCode, desktopUrl, mobileUrl }) => ({ langCode, url: relation === PlatformRelation.desktop ? desktopUrl : mobileUrl }))
  }

  public getJsonLd(json: Record<PlatformRelation, string>, relation: PlatformRelation = PlatformRelation.desktop): string {
    return json[relation]
  }

  // TODO: CHANGE TO COMMENTED VERSION BELOW WHEN REDIRECTS TO NEW DOMAIN ARE READY
  private getCorrectLocale(locale: LanguageCodesEnum): string {
    let correctLocale

    if (this.isNewRobotaDomainService.isNewRobotaDomain()) {
      correctLocale = locale === LanguageCodesEnum.UK ? '' : 'ru'
    } else {
      correctLocale = locale === LanguageCodesEnum.UK ? 'ua' : ''
    }
    return correctLocale
  }
  // private getCorrectLocale(locale: LanguageCodesEnum): string {
  //   correctLocale = locale === LanguageCodesEnum.UK ? '' : 'ru'
  // }
}
