import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import { FooterBaseComponent } from './components/footer/base/base.component'
import { FooterDesktopComponent } from './components/footer/desktop/desktop.component'
import { FooterMobileComponent } from './components/footer/mobile/mobile.component'

import { SharedIconsModule } from '@alliance/shared/icons'
import { SharedTranslationModule } from '@alliance/shared/translation'

import { SantaInputModule } from '@alliance/santa/input'
import { SantaCheckboxModule } from '@alliance/santa/checkbox'
import { SantaButtonModule } from '@alliance/santa/button'

import { ClubsUiLogoModule } from '@alliance/clubs/ui/logo'
import { ClubsUiLinksModule } from '@alliance/clubs/ui/links'

const SantaModules = [SantaInputModule, SantaCheckboxModule, SantaButtonModule]

const SharedModules = [SharedIconsModule, SharedTranslationModule]

const ClubsModules = [ClubsUiLogoModule, ClubsUiLinksModule]

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, RouterModule, ...SantaModules, ...SharedModules, ...ClubsModules],
  declarations: [FooterBaseComponent, FooterDesktopComponent, FooterMobileComponent],
  exports: [FooterDesktopComponent, FooterMobileComponent]
})
export class ClubsFooterModule {}
