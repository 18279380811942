<alliance-clubs-shell-header [style.height.px]="HEADER_HEIGHT" [headerData]="state.headerData"></alliance-clubs-shell-header>

<div class="santa-flex santa-bg-black-100">
  <alliance-clubs-sidebar *ngIf="state?.showSidebar"></alliance-clubs-sidebar>
  <div class="app-content">
    <div class="santa-flex-grow">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!state?.isWebview">
      <!-- DESKTOP FOOTER -->
      <ng-container *ngIf="!state?.isMobileScreen">
        <alliance-clubs-footer-desktop-component></alliance-clubs-footer-desktop-component>
      </ng-container>

      <!-- MOBILE FOOTER -->
      <ng-container *ngIf="state?.isMobileScreen">
        <alliance-clubs-footer-mobile-component></alliance-clubs-footer-mobile-component>
      </ng-container>
    </div>
  </div>
</div>

<alliance-clubs-banners-about *ngIf="state?.showBannerAbout"></alliance-clubs-banners-about>
