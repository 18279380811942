import { Injectable } from '@angular/core'
import { Organization } from 'schema-dts'
import { SeoParamsResponseModel } from '@alliance/shared/seo'
import { BudniBaseDictionaryService } from './budni-base-dictionary.service'

@Injectable({
  providedIn: 'root'
})
export class BudniPageDictionaryService extends BudniBaseDictionaryService {
  public getSeoParams(): SeoParamsResponseModel {
    return {
      title: this.translationService.translate(this.translations.budni.title),
      description: this.translationService.translate(this.translations.budni.description),
      h1: [this.translationService.translate(this.translations.budni.h1)],
      canonicalUrl: this.getBaseUrl(),
      hrefLang: [],
      alternateUrl: '',
      noIndexNoFollow: false,
      jsonLd: this.getJsonLd()
    }
  }

  private getJsonLd(): string {
    return this.helpersService.createJsonLd<Organization>({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Budni',
      alternateName: this.translationService.translate(this.translations.budni.title),
      url: this.getBaseUrl(),
      logo: 'https://images.cf-rabota.com.ua/clubs/logo.svg'
    })
  }
}
