import { isDevMode, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

import { InMemoryCache } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'

import { Environment } from '@alliance/shared/environment'
import { GetApolloClientMetricsService } from '@alliance/shared/gql/utils'
import { GqlLanguageMiddlewareService } from '@alliance/shared/translation'

const APOLLO_CLIENT_NAME = 'clubs'

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [GqlLanguageMiddlewareService]
})
export class ClubsDomainModule {
  public constructor(
    private apollo: Apollo,
    private environment: Environment,
    private httpLink: HttpLink,
    private langMiddleware: GqlLanguageMiddlewareService,
    private getApolloClientMetricsService: GetApolloClientMetricsService
  ) {
    if (!this.apollo.use(APOLLO_CLIENT_NAME)) {
      this.createClient()
    }
  }

  private createClient(): void {
    const cache = new InMemoryCache()

    const httpLink = this.httpLink.create({
      uri: this.environment.clubsApi,
      withCredentials: false
    })

    this.apollo.createNamed(APOLLO_CLIENT_NAME, {
      link: this.langMiddleware.getMiddleware().concat(httpLink),
      cache,
      ...this.getApolloClientMetricsService.apolloClientMetrics,
      connectToDevTools: isDevMode()
    })
  }
}
