import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'

import { LogoBudni } from '@alliance/shared/icons'
import { RxStateComponent } from '@alliance/shared/models'
import { MediaService, ScrollHelperService } from '@alliance/shared/utils'

import { Translations } from '../localization/translations'

@Component({
  selector: 'alliance-clubs-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent extends RxStateComponent<{
  isMobileScreen: boolean
  withDescription: boolean
}> {
  @Output() public logoClicked = new EventEmitter<void>()

  public readonly LogoBudni = LogoBudni.name
  public state = this.connectViewState(['isMobileScreen', 'withDescription'])

  public constructor(private mediaService: MediaService, public translations: Translations, private scrollHelperService: ScrollHelperService) {
    super()

    this.initState({
      isMobileScreen: this.mediaService.select('isMobileScreen'),
      withDescription: false
    })
  }

  @Input()
  public set withDescription(value: boolean) {
    this.set({ withDescription: value })
  }

  public emitCloseSidebar(): void {
    this.logoClicked.emit()
  }

  public scrollToTop(): void {
    this.scrollHelperService.scrollTo({ topPosition: 0 })
  }
}
