<div class="sidebar" [class.sidebar-inner]="!state?.isOuterType">
  <div class="sidebar-header" *ngIf="state?.isOuterType" [class.sidebar-header-reverse]="!state?.isMobileScreen">
    <span class="santa-ml-20"><alliance-clubs-logo (logoClicked)="closeSidebar()"></alliance-clubs-logo></span>

    <!-- CLOSE ICON -->
    <div class="sidebar-close" (click)="closeSidebar()">
      <alliance-icon [name]="ICONS.IconCloseCross" [inheritColor]="true"></alliance-icon>
    </div>
  </div>

  <div class="sidebar-rubrics">
    <alliance-clubs-sidebar-rubrics-list (rubricClicked)="closeSidebar()"></alliance-clubs-sidebar-rubrics-list>
  </div>

  <div class="sidebar-navigation">
    <alliance-clubs-sidebar-navigation></alliance-clubs-sidebar-navigation>
  </div>
</div>
