import { ROBOTA_UA } from '@alliance/shared/constants'

export default {
  about: {
    title: 'Про Budni',
    project: 'Про проект',
    mediakit: 'Реклама',
    privacy: 'Конфіденційність'
  },
  products: {
    title: 'Наші продукти',
    seeker: `${ROBOTA_UA} для пошукачів`,
    employer: `${ROBOTA_UA} для роботодавця`
  },
  roadToVictory: {
    title: 'Працюємо на перемогу разом',
    posts: {
      first: 'Як допомогти українській армії: усі можливі варіанти',
      second: 'Telegram-бот для пошуку роботи від robota.ua',
      fourth: 'Алгоритм дій при різних видах ушкоджень'
    }
  },
  subscribe: {
    title: 'Підпишіться',
    enterEmail: 'Введіть email',
    forSeeker: 'Для пошукачів',
    forEmployer: 'Для роботодавців',
    buttonLabel: 'Підписатись'
  },
  budniDescription: 'Медіаплатформа про роботу та життя',
  robotaDescription: `budni.ua — проект від ${ROBOTA_UA}`,
  terms: {
    title: 'ТОВ «Робота Інтернешнл» © 2022' /* yaspeller ignore */,
    text:
      "Всі права захищені та охороняються діючим законодавством України. Використовуючи наші матеріали обов'язково ставте активне посилання в першому або другому абзацах на вихідну статтю і вкажіть джерело budni.ua." /* yaspeller ignore */
  }
}
