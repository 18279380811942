import { ChangeDetectionStrategy, Component } from '@angular/core'

import { IsWebviewService, SidebarNavigationLink } from '@alliance/clubs/utils'

import { SkeletonClubsNavigation } from '@alliance/shared/icons'
import { Environment } from '@alliance/shared/environment'
import { RxStateComponent } from '@alliance/shared/models'

import { Translations } from '../../localization/translations'

@Component({
  selector: 'alliance-clubs-sidebar-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['./navigation.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarNavigationComponent extends RxStateComponent<{
  isWebview: boolean
}> {
  public readonly state = this.connectViewState(['isWebview'])
  public readonly SkeletonClubsNavigation = SkeletonClubsNavigation
  public readonly links: Record<'seeker' | 'employer' | 'mediakit' | 'about', SidebarNavigationLink> = {
    seeker: {
      path: this.environment.mainSiteUrl ?? '',
      label: this.translations.navigation.seeker,
      target: '_blank'
    },
    employer: {
      path: `${this.environment.mainSiteUrl ?? ''}employer`,
      label: this.translations.navigation.employer,
      target: '_blank'
    },
    mediakit: {
      path: 'https://mediakit.rabota.ua/',
      label: this.translations.navigation.mediakit,
      target: '_blank'
    },
    about: {
      path: '/about-budni',
      label: this.translations.navigation.about
    }
  }

  public constructor(private translations: Translations, private environment: Environment, private isWebviewService: IsWebviewService) {
    super()

    this.initState({
      isWebview: this.isWebviewService.select('isWebview')
    })
  }
}
