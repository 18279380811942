import { UrlMatchResult, UrlSegment } from '@angular/router'

export const tagsPageMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  const length = segments.length
  const isTagsPage = length ? segments[0].toString() === 'tags' : null
  const tagsName = segments[1] ? segments[1] : null

  if (length === 2 && isTagsPage && tagsName) {
    return { consumed: segments, posParams: { tagSegmentsName: tagsName } }
  }

  return null
}
