<alliance-clubs-banners-layout *ngIf="!state?.hideBudniBannerAbout">
  <div class="about">
    <div class="budni"><img [src]="budniSrc" width="auto" height="20" alt="budni" loading="eager" /></div>
    <div class="budni-text">
      {{ translations.about.text | translate }}
    </div>
    <a class="budni-cta" (click)="close()" href="" [routerLink]="[newsPostLink]">
      <santa-button [fullWidth]="true">{{ translations.about.cta | translate }}</santa-button>
    </a>
    <alliance-icon class="about-close" (click)="close()" [name]="IconCross" [inheritColor]="true" [widthPercent]="100"></alliance-icon>
  </div>
</alliance-clubs-banners-layout>
