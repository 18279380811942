export default {
  budni: {
    title: `Budni - онлайн-журнал сайта robota.ua о поиске работы, карьере и управлением персоналом.`,
    description:
      'Советы и мысли экспертов, интервью с професионалами, истории украинцев. Рецензии на книги и афиша интересных событий, эекспертные материалы в сфере HR, упревлении персоналом, бренды работодателя и карьеры.',
    h1: `Budni - онлайн-журнал сайта robota.ua о поиске работы, карьере и управлением персоналом.`,
    breadcrumbs: {
      home: 'Главная'
    },
    rubricPageTitle: '{rubricName} ➣ Budni - онлайн-журнал сайта robota.ua о поиске работы',
    rubricPageDescription:
      'Онлайн-журнал Budni от robota.ua ➣ {rubricName} - Советы и мнения экспертов, интервью с профессионалами, истории украинцев, рецензии на книги и афиша интересных событий, экспертные материалы в сфере HR, управлении персоналом, бренды работодателя и карьера.',
    tagPageTitle: '{tagName} ➣ Budni - онлайн-журнал сайта robota.ua ',
    tagPageDescription:
      'Онлайн-журнал Budni от robota.ua ➣ {tagName} - Советы и мнения экспертов, интервью с профессионалами, истории украинцев, рецензии на книги и афиша интересных событий, экспертные материалы в сфере HR, управлении персоналом, бренды работодателя и карьера.'
  }
}
