import { Environment } from '@alliance/shared/environment'
import { NgModule } from '@angular/core'
import { TRANSLOCO_CONFIG, TranslocoDirective, TranslocoModule, TranslocoPipe } from '@ngneat/transloco'
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat'
import { TranslatePipe } from './translate/translate.pipe'
import { translocoInit } from './transloco.loader'

@NgModule({
  imports: [TranslocoModule, TranslocoMessageFormatModule.forRoot({ locales: ['ru-RU', 'uk-UA'] })],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: translocoInit,
      deps: [Environment]
    }
  ],
  exports: [TranslocoPipe, TranslocoDirective, TranslatePipe],
  declarations: [TranslatePipe]
})
export class SharedTranslationModule {}
