import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'alliance-clubs-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinksComponent {
  @Input() public isInternal = false
  @Input() public link = ''
}
