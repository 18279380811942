import { log } from '@alliance/shared/logger'
import { Injectable } from '@angular/core'

import { map, Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { GetPostsRubricsListGQL } from './rubrics-list.generated'
import { ClubTopic } from '../../types-generated'

@Injectable({
  providedIn: 'root'
})
export class PostsRubricsListService {
  public constructor(private getPostsRubricsListGQL: GetPostsRubricsListGQL) {}

  public getPostsRubricsList$(): Observable<ClubTopic[] | null> {
    return this.getPostsRubricsListGQL.fetch({}, { fetchPolicy: 'cache-first' }).pipe(
      map(({ data }) => data?.clubs?.topics),
      catchError(e => {
        log.error({ where: 'clubs-domain: PostsRubricsListService', category: 'api_call_failed', message: 'GetPostsRubricsListGQL query failed', error: e })
        return of(null)
      })
    )
  }
}
