import { NgModule } from '@angular/core'

import { ShellComponent } from './shell/shell.component'

@NgModule({
  imports: [],
  declarations: [ShellComponent],
  exports: [ShellComponent]
})
export class ClubsShellHeaderModule {}
