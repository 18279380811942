<santa-skeleton-loader [loading]="!links" [showProgressBar]="false" [skeleton]="SkeletonClubsNavigation" [skeletonWidth]="0">
  <ng-template #content>
    <div class="santa-flex santa-flex-col">
      <div class="sidebar-navigation-item" *ngIf="!state?.isWebview">
        <a [href]="links.seeker.path" [target]="links.seeker.target">
          {{ links.seeker.label | translate }}
        </a>
      </div>
      <div class="sidebar-navigation-item" *ngIf="!state?.isWebview">
        <a [href]="links.employer.path" [target]="links.employer.target">
          {{ links.employer.label | translate }}
        </a>
      </div>
      <div class="sidebar-navigation-item">
        <a [href]="links.mediakit.path" [target]="links.mediakit.target" class="santa-mr-20">
          {{ links.mediakit.label | translate }}
        </a>
        <a href="" [routerLink]="links.about.path">
          {{ links.about.label | translate }}
        </a>
      </div>
    </div>
  </ng-template>
</santa-skeleton-loader>
