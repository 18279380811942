import { Injectable } from '@angular/core'
import { CanActivateChild, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'

import { HeaderMainService } from '@alliance/clubs/header'

@Injectable()
export class HeaderGuard implements CanActivateChild {
  public constructor(private headerMainService: HeaderMainService) {}
  public canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.headerMainService.setDefault()
    return true
  }
}
