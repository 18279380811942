import { NgModule } from '@angular/core'
import { BrowserTransferStateModule } from '@angular/platform-browser'

import { SharedBrowserStorageModule } from '@alliance/shared/storage'

import { AppModule } from './app.module'
import { AppComponent } from './app.component'

@NgModule({
  imports: [AppModule, BrowserTransferStateModule, SharedBrowserStorageModule.forRoot()],
  bootstrap: [AppComponent]
})
export class AppBrowserModule {}
