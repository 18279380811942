import { Injectable } from '@angular/core'
import { Organization } from 'schema-dts'
import { SeoParamsResponseModel } from '@alliance/shared/seo'
import { BudniTagsPageModel } from '../models/budni-tags-page.model'
import { BudniBaseDictionaryService } from './budni-base-dictionary.service'

@Injectable({
  providedIn: 'root'
})
export class BudniTagsPageDictionaryService extends BudniBaseDictionaryService {
  public getSeoParams(params?: BudniTagsPageModel): SeoParamsResponseModel {
    return {
      title: this.translationService.translate(this.translations.budni.tagPageTitle, { tagName: params?.tagName ?? '' }),
      description: this.translationService.translate(this.translations.budni.tagPageDescription, {
        tagName: params?.tagName ?? ''
      }),
      h1: [this.translationService.translate(this.translations.budni.h1)],
      canonicalUrl: this.helpersService.createURL(this.getBaseUrl(), `tags/${params?.tagName ?? ''}`),
      hrefLang: [],
      alternateUrl: '',
      noIndexNoFollow: false,
      jsonLd: this.getJsonLd(params)
    }
  }

  private getJsonLd(params?: BudniTagsPageModel): string {
    return this.helpersService.createJsonLd<Organization>({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Budni',
      alternateName: this.translationService.translate(this.translations.budni.tagPageTitle, { tagName: params?.tagName ?? '' }),
      url: this.helpersService.createURL(this.getBaseUrl(), `tags/${params?.tagName ?? ''}`),
      logo: 'https://images.cf-rabota.com.ua/clubs/logo.svg'
    })
  }
}
