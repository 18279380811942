import { ChangeDetectionStrategy, Component } from '@angular/core'

import { FooterBaseComponent } from '../base/base.component'

import { IconGrayArrowsVerticalDropdown } from '@alliance/shared/icons'

@Component({
  selector: 'alliance-clubs-footer-mobile-component',
  templateUrl: './mobile.component.html',
  styleUrls: ['../base/base.component.tw.css', './mobile.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterMobileComponent extends FooterBaseComponent {
  public readonly IconGrayArrowsVerticalDropdown = IconGrayArrowsVerticalDropdown.name

  public toggleItemsVisibility(i: number): void {
    this.content[i].showItemsInMobile = !this.content[i].showItemsInMobile
  }
}
