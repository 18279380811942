import { ROBOTA_UA } from '@alliance/shared/constants'

export default {
  about: {
    title: 'Про Budni',
    project: 'Про проект',
    mediakit: 'Реклама',
    privacy: 'Конфиденциальность'
  },
  products: {
    title: 'Наши продукты',
    seeker: `${ROBOTA_UA} для соискателей`,
    employer: `${ROBOTA_UA} для работодателей`
  },
  roadToVictory: {
    title: 'Працюємо на перемогу разом' /* yaspeller ignore */,
    posts: {
      first: 'Як допомогти українській армії: усі можливі варіанти' /* yaspeller ignore */,
      second: 'Telegram-бот для пошуку роботи від robota.ua' /* yaspeller ignore */,
      fourth: 'Алгоритм дій при різних видах ушкоджень' /* yaspeller ignore */
    }
  },
  subscribe: {
    title: 'Подпишитесь',
    enterEmail: 'Введите email',
    forSeeker: 'Для соискателей',
    forEmployer: 'Для работодателей',
    buttonLabel: 'Подписаться'
  },
  budniDescription: 'Медиаплатформа про работу и жизнь',
  robotaDescription: `budni.ua — проект от ${ROBOTA_UA}`,
  terms: {
    title: 'ТОВ «Робота Інтернешнл» © 2022' /* yaspeller ignore */,
    text:
      "Всі права захищені та охороняються діючим законодавством України. Використовуючи наші матеріали обов'язково ставте активне посилання в першому або другому абзацах на вихідну статтю і вкажіть джерело budni.ua." /* yaspeller ignore */
  }
}
