import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild, UrlTree } from '@angular/router'

import { Observable } from 'rxjs'

import { IsWebviewService } from '@alliance/clubs/utils'

@Injectable()
export class IsWebviewGuard implements CanActivateChild {
  public constructor(private isWebviewService: IsWebviewService) {}
  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isWebview = route.queryParamMap.get('p') === 'webview'
    this.isWebviewService.setIsWebview(isWebview)
    return true
  }
}
