import { Injectable } from '@angular/core'

import { RxStateService } from '@alliance/shared/models'
import { HeaderPositionModeEnum } from '@alliance/shared/header/utils'

@Injectable({
  providedIn: 'root'
})
export class HeaderMainService extends RxStateService<{
  mode: HeaderPositionModeEnum | null
  showBackButton: boolean
}> {
  public constructor() {
    super()

    this.initState({
      mode: null,
      showBackButton: false
    })
  }

  public setDefault(): void {
    this.set({ mode: null, showBackButton: false })
  }
}
