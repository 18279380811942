import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { Observable } from 'rxjs'

import { DetectPlatformService, LoggingToBigQueryService, NavigationService } from '@alliance/shared/utils'
import { log } from '@alliance/shared/logger'

@Injectable()
export class PageViewAngularGuard implements CanActivateChild {
  public prevUrl = ''
  public currentUrl = ''

  public constructor(
    private bqService: LoggingToBigQueryService,
    private platform: DetectPlatformService,
    private titleService: Title,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.platform.isServer) {
      return true // disallow logic below for server-platform
    }

    this.prevUrl = this.currentUrl

    if (childRoute.component) {
      this.currentUrl = state.url
      this.navigationService.pushToHistory(state.url)
      this.trackPageViews()
    }

    return true
  }

  private trackPageViews(): void {
    let routechange: string
    let routerefferer: string

    try {
      routechange = decodeURI(this.currentUrl)
      routerefferer = this.prevUrl ? decodeURI(this.prevUrl) : decodeURI(this.document?.defaultView?.document?.referrer ?? '')
    } catch (e) {
      // If decodeURI failed, take url string without decodeURI
      routechange = this.currentUrl
      routerefferer = this.prevUrl ? this.prevUrl : this.document?.defaultView?.document.referrer ?? ''
      log.error({ where: 'clubs', category: 'try_catch', message: 'trackPageViews failed', error: e, currentUrl: this.currentUrl, prevUrl: this.prevUrl })
    }

    this.bqService.pushToDataLayer({
      event: 'pageviewAngular',
      routechange,
      routerefferer,
      routehost: this.document.defaultView?.location?.hostname,
      title: this.titleService.getTitle()
    })
  }
}
