import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SeoParamsResponseModel } from '../models/seo-params-response.model'
import { LanguageCodesEnum } from '@alliance/shared/translation'

@Injectable({ providedIn: 'root' })
export abstract class AbstractSeoDictionaryService<T> {
  protected readonly DEFAULT_RESPONSE_SEO_PARAMS: SeoParamsResponseModel = {
    title: '',
    description: '',
    alternateUrl: '',
    canonicalUrl: '',
    noIndexNoFollow: false,
    hrefLang: [
      {
        langCode: LanguageCodesEnum.UK,
        url: ''
      },
      {
        langCode: LanguageCodesEnum.RU,
        url: ''
      }
    ],
    h1: [],
    jsonLd: ''
  }

  public abstract getSeoParams$(param: T): Observable<SeoParamsResponseModel>
}
