import { Compiler, Injector, NgModule } from '@angular/core'
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser'
import { ChildrenOutletContexts, Router, RouterModule, UrlSerializer } from '@angular/router'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { Location } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppComponent } from './app.component'

import { Environment } from '@alliance/shared/environment'
import { SharedAuthApiModule } from '@alliance/shared/auth/api'
import { SharedSSRTransferStateModule } from '@alliance/shared/ssr/transfer-state'
import { SharedBrowserStorageModule } from '@alliance/shared/storage'
import { SharedSantaModule } from '@alliance/shared/santa'
import { SharedIconsModule } from '@alliance/shared/icons'
import { CustomLanguageRouter, LanguageCodesEnum, SharedTranslationModule, TranslationService } from '@alliance/shared/translation'
import { IsNewRobotaDomainService, OpenGraphHelperService } from '@alliance/shared/utils'
import { SharedGoogleAnalyticModule } from '@alliance/shared-google-analytics'

import { environment } from '../environments/environment'
import { AppRoutingModule, routes, routesDefaultUa } from './app-routing.module'
import { SidebarGuard } from '../guards/sidebar.guard'
import { HeaderGuard } from '../guards/header.guard'
import { PageViewAngularGuard } from '../guards/pageview-angular.guard'
import { IsWebviewGuard } from '../guards/isWebview.guard'

import { ClubsSidebarModule } from '@alliance/clubs/sidebar'
import { ClubsShellHeaderModule } from '@alliance/clubs/shell-header'
import { ClubsFooterModule } from '@alliance/clubs/footer'
import { ErrorHandlerInterceptor } from '@alliance/clubs/utils'
import { ClubsBannersModule } from '@alliance/clubs/banners'
import { SharedSeoModule } from '@alliance/shared/seo'
import { BudniDictionaryService, BudniSeoParams } from '@alliance/clubs/seo'

const AngularModules = [BrowserModule.withServerTransition({ appId: 'app-clubs' }), RouterModule, HttpClientModule, BrowserTransferStateModule, BrowserAnimationsModule]

const SharedModules = [
  SharedTranslationModule,
  SharedAuthApiModule.forRoot({ logoutUnAuthorizedUsers: true, retryFailedGetRequests: true }),
  SharedSSRTransferStateModule,
  SharedBrowserStorageModule.forRoot(),
  SharedSantaModule,
  SharedIconsModule,
  SharedGoogleAnalyticModule
]

const ClubsModules = [ClubsSidebarModule, ClubsShellHeaderModule, ClubsFooterModule, ClubsBannersModule]

const ClubsGuards = [SidebarGuard, HeaderGuard, PageViewAngularGuard, IsWebviewGuard]

const ClubsInterceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlerInterceptor,
    multi: true
  }
]

@NgModule({
  declarations: [AppComponent],
  imports: [AngularModules, AppRoutingModule, ...SharedModules, ...ClubsModules, SharedSeoModule.forRoot<BudniSeoParams>(BudniDictionaryService)],
  providers: [
    ...ClubsGuards,
    ...ClubsInterceptors,
    OpenGraphHelperService,
    { provide: Environment, useValue: environment },
    {
      provide: Router,
      useFactory: (
        urlSerializer: UrlSerializer,
        rootContexts: ChildrenOutletContexts,
        location: Location,
        injector: Injector,
        compiler: Compiler,
        translationService: TranslationService,
        isNewRobotaDomainService: IsNewRobotaDomainService
      ): CustomLanguageRouter =>
        new CustomLanguageRouter(
          AppComponent,
          urlSerializer,
          rootContexts,
          location,
          injector,
          compiler,
          isNewRobotaDomainService.isNewRobotaDomain() ? routesDefaultUa : routes,
          translationService,
          isNewRobotaDomainService
        ),
      deps: [UrlSerializer, ChildrenOutletContexts, Location, Injector, Compiler, TranslationService, IsNewRobotaDomainService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(private translationService: TranslationService) {
    this.translationService.setDefault(LanguageCodesEnum.UK)
  }
}
