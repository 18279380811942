<a *ngIf="isInternal; else externalLink" href="" [routerLink]="link" class="link">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #externalLink>
  <a [href]="link" target="_blank" class="link">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>

<ng-template #content><ng-content></ng-content></ng-template>
