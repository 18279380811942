import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  public getPostPageRouterLink(params: { topicName: string; postSlug: string }): string {
    return `/${params.topicName}/${params.postSlug}`
  }

  public getRubricPageRouterLink(params: { rubricName: string }): string {
    return `/${params.rubricName}`
  }
}
