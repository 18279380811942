import { Injectable } from '@angular/core'

import { RxStateService } from '@alliance/shared/models'
import { SidebarTypesEnum } from '@alliance/clubs/utils'

@Injectable({
  providedIn: 'root'
})
export class SidebarMainService extends RxStateService<{
  type: SidebarTypesEnum
}> {
  public constructor() {
    super()

    this.initState({
      type: SidebarTypesEnum.inner
    })
  }

  public setDefault(): void {
    this.set({ type: SidebarTypesEnum.inner })
  }
}
