import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus'
import { NgxMaskModule } from 'ngx-mask'

import { SantaInputComponent } from './input.component'

@NgModule({
  imports: [CommonModule, FormsModule, PolymorpheusModule, NgxMaskModule],
  declarations: [SantaInputComponent],
  exports: [SantaInputComponent]
})
export class SantaInputModule {}
