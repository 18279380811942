<div class="santa-relative" *ngIf="state.loading">
  <santa-indeterminate-progress-bar class="santa-absolute santa-w-full" [useProgressBar]="showProgressBar"></santa-indeterminate-progress-bar>
</div>

<div
  class="santa-h-full"
  [ngClass]="{
    'santa-hidden': state.first,
    'santa-opacity-50': state.loading && !state.first
  }"
>
  <ng-container *ngIf="content">
    <ng-container *polymorpheusOutlet="content as loading; context: prepareContext()">
      <span *ngIf="loading" class="santa-inline-block santa-py-10 santa-px-20 santa-cursor-pointer" (click)="setLoading(false)"> Change: {{ loading }} </span>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="state.first && (loadingFirst || state.loading)">
  <alliance-icon
    *ngIf="state.skeleton as skeleton"
    class="santa-block santa-mx-auto santa-my-0"
    [ngClass]="useClasses"
    [name]="skeleton.name"
    [widthPercent]="!skeletonWidth ? 100 : 0"
    [width]="skeletonWidth ?? 0"
  ></alliance-icon>
</ng-container>

<ng-container *ngIf="state.empty && !state.loading">
  <ng-container *ngIf="emptyContent">
    <ng-container *polymorpheusOutlet="emptyContent as empty; context: prepareContext()">
      <span *ngIf="empty" class="santa-inline-block santa-py-10 santa-px-20 santa-cursor-pointer" (click)="setIsEmpty(false)"> Change: {{ empty }} </span>
    </ng-container>
  </ng-container>
</ng-container>
