import { Injectable } from '@angular/core'

import { RxStateService } from '@alliance/shared/models'

@Injectable({
  providedIn: 'root'
})
export class IsWebviewService extends RxStateService<{
  isWebview: boolean
}> {
  public constructor() {
    super()

    this.initState({
      isWebview: false
    })
  }

  public setIsWebview(value: boolean): void {
    const { isWebview } = this.get()
    // FOR ONCE IS SET TO TRUE
    if (!isWebview) {
      this.set({ isWebview: value })
    }
  }
}
