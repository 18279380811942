import { Environment } from '@alliance/shared/environment'
import { TranslocoConfig } from '@ngneat/transloco'
import { LanguageCodesEnum } from './models/language-codes-enum'

export const translocoInit = (environment: Environment): TranslocoConfig => ({
  availableLangs: [LanguageCodesEnum.RU, LanguageCodesEnum.UK],
  defaultLang: LanguageCodesEnum.RU,
  prodMode: environment.production,
  reRenderOnLangChange: true,
  missingHandler: {
    allowEmpty: true
  }
})
