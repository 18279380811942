import { ROBOTA_UA } from '@alliance/shared/constants'

export default {
  navigation: {
    seeker: `${ROBOTA_UA} для соискателей`,
    employer: `${ROBOTA_UA} для работодателей`,
    mediakit: 'Реклама',
    about: 'О нас'
  }
}
