import { Injectable } from '@angular/core'
import { safeJsonParse, getNonNullableItems } from '@alliance/shared/utils'
import { tap, map } from 'rxjs/operators'
import { BehaviorSubject, Observable } from 'rxjs'
import { BreadcrumbList, ItemList } from 'schema-dts'
import { SeoDomManipulatingService } from './seo-dom-manipulating.service'
import { SeoParamsResponseModel } from '../models/seo-params-response.model'
import { AbstractSeoDictionaryService } from './abstract-seo-dictionary.service'
import { BaseSeoParams } from '../models/base-seo-params'

@Injectable({ providedIn: 'root' })
export class SeoService<SeoParams extends BaseSeoParams = BaseSeoParams> {
  public h1$ = new BehaviorSubject<string[]>([])
  public breadcrumbList$ = new BehaviorSubject<ItemList[]>([])
  public canonicalUrl$ = new BehaviorSubject<string>('')

  public constructor(private abstractSeoDictionaryService: AbstractSeoDictionaryService<SeoParams>, private seoDomManipulatingService: SeoDomManipulatingService) {}

  public getBreadcrumbsList(jsonLd: string): ItemList[] {
    const jsonLdArray = safeJsonParse<unknown[]>(jsonLd.length ? jsonLd : [], [])
    if (jsonLdArray && jsonLdArray.length) {
      const breadcrumbs = jsonLdArray.find(this.itemIsBreadcrumbs.bind(this))
      return breadcrumbs?.itemListElement && Array.isArray(breadcrumbs.itemListElement) ? getNonNullableItems<ItemList>(breadcrumbs?.itemListElement) : []
    }
    return []
  }

  public getSeoParams$(params: SeoParams): Observable<SeoParamsResponseModel> {
    return this.abstractSeoDictionaryService.getSeoParams$(params).pipe(
      tap(({ h1, canonicalUrl, jsonLd }) => {
        this.breadcrumbList$.next(this.getBreadcrumbsList(jsonLd))
        this.h1$.next(h1)
        this.canonicalUrl$.next(canonicalUrl)
      })
    )
  }

  public setDefaultSeo(): void {
    this.h1$.next([])
    this.canonicalUrl$.next('')
    this.seoDomManipulatingService.setDefaultSeo()
  }

  public setSeoParamsInDOM(params: SeoParamsResponseModel): void {
    this.seoDomManipulatingService.setAllParamsInDOM(params)
  }

  public setSeoParams$(params: SeoParams): Observable<SeoParamsResponseModel> {
    return this.getSeoParams$(params).pipe(
      map(seoParams => {
        this.setSeoParamsInDOM(seoParams)
        return seoParams
      })
    )
  }

  private itemIsBreadcrumbs(item: unknown): item is BreadcrumbList {
    return item instanceof Object && '@type' in item && item['@type'] === 'BreadcrumbList'
  }
}
