import { Injectable } from '@angular/core'
import { CanActivateChild, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'

import { SidebarMainService } from '@alliance/clubs/sidebar'

@Injectable()
export class SidebarGuard implements CanActivateChild {
  public constructor(private sidebarMainService: SidebarMainService) {}
  public canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.sidebarMainService.setDefault()
    return true
  }
}
