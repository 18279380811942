<santa-skeleton-loader [loading]="!state.list" [class.santa-px-20]="!state.list" [showProgressBar]="false" [skeleton]="SkeletonClubsRubricsList" [skeletonWidth]="0">
  <ng-template #content>
    <div>
      <a class="rubric-item" *ngFor="let rubric of state?.list; trackBy: trackByFn" [routerLink]="[getRubricPageRouterLink(rubric)]" routerLinkActive="active" (click)="emitCloseSidebar()">
        <span class="santa-flex santa-mr-10" *ngIf="rubric.sideBarIcon">
          <img width="24" height="24" [src]="env.clubsStaticAWS + rubricsAWSDirectories.icons + rubric.sideBarIcon | bypassSanitizer: 'url'" alt="rubrics post logo" loading="eager" />
        </span>
        {{ rubric.name }}
      </a>
    </div>
  </ng-template>
</santa-skeleton-loader>
