import { UrlMatchResult, UrlSegment } from '@angular/router'

import { isExistedTopic } from '../helpers/helpers'

export const postPageMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  const length = segments.length
  const topicName = length ? segments[0].toString() : null

  if (length === 2 && topicName && isExistedTopic(topicName)) {
    return { consumed: segments, posParams: { postSegmentsSlug: segments[1] } }
  }

  return null
}
