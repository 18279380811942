import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router'
import { Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { DetectPlatformService, OpenGraphHelperService } from '@alliance/shared/utils'
import { SeoService } from '@alliance/shared/seo'
import { BudniSeoKeysEnum } from '@alliance/clubs/seo'
import { TWITTER_BUDNI } from '@alliance/clubs/utils'

@Injectable({ providedIn: 'root' })
export class CommonSeoGuard implements CanActivateChild {
  public constructor(private seoService: SeoService, private platform: DetectPlatformService, private openGraphHelperService: OpenGraphHelperService) {}

  public canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (!next.component) {
      return true
    }
    if (this.platform.isServer) {
      this.seoService.setDefaultSeo()

      const { seoKey } = next.data ?? {}
      if (seoKey === BudniSeoKeysEnum.budni) {
        return this.seoService.setSeoParams$({ key: BudniSeoKeysEnum.budni }).pipe(
          switchMap(seoParams => {
            this.openGraphHelperService.addOgTags({
              url: seoParams.canonicalUrl,
              title: seoParams.title,
              description: seoParams.description,
              image: 'https://images.cf-rabota.com.ua/clubs/OG/homepage.png',
              twitterSite: TWITTER_BUDNI,
              twitterTitle: seoParams.title,
              twitterDescription: seoParams.description
            })
            return of(true)
          })
        )
      }

      return true
    } else {
      return true
    }
  }
}
