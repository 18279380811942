import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { SidebarMainComponent } from './components/main/main.component'
import { SidebarRubricsListComponent } from './components/rubrics-list/rubrics-list.component'
import { SidebarNavigationComponent } from './components/navigation/navigation.component'

import { ClubsDomainModule } from '@alliance/clubs/domain'
import { ClubsUtilsModule } from '@alliance/clubs/utils'
import { ClubsUiLogoModule } from '@alliance/clubs/ui/logo'

import { SharedIconsModule } from '@alliance/shared/icons'
import { SharedTranslationModule } from '@alliance/shared/translation'
import { SharedPipesBypassSanitizerModule } from '@alliance/shared/pipes/bypass-sanitizer'

import { SantaSkeletonLoaderModule } from '@alliance/santa/skeleton-loader'

const SharedModules = [SharedIconsModule, SharedTranslationModule, SharedPipesBypassSanitizerModule]

const ClubsModules = [ClubsDomainModule, ClubsUtilsModule, ClubsUiLogoModule]

const SantaModules = [SantaSkeletonLoaderModule]

@NgModule({
  imports: [CommonModule, RouterModule, ...SharedModules, ...ClubsModules, ...SantaModules],
  declarations: [SidebarMainComponent, SidebarRubricsListComponent, SidebarNavigationComponent],
  exports: [SidebarMainComponent]
})
export class ClubsSidebarModule {}
