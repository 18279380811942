import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { AboutComponent } from './components/about/about.component'
import { LayoutComponent } from './components/layout/layout.component'

import { SantaButtonModule } from '@alliance/santa/button'
import { SharedIconsModule } from '@alliance/shared/icons'
import { SharedTranslationModule } from '@alliance/shared/translation'

const SantaModules = [SantaButtonModule]

const SharedModules = [SharedIconsModule, SharedTranslationModule]

@NgModule({
  imports: [CommonModule, RouterModule, ...SantaModules, ...SharedModules],
  declarations: [AboutComponent, LayoutComponent],
  exports: [AboutComponent]
})
export class ClubsBannersModule {}
