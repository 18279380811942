<div class="footer">
  <!-- MAIN CONTENT -->
  <div class="footer-main-content">
    <!--  ABOUT, PRODUCTS, ROAD_TO_VICTORY  -->

    <div *ngFor="let cnt of content; let first = first; trackBy: trackByFn" [class.margin-left-depends-on-screen-size]="!first">
      <p class="footer-ul-title">{{ cnt.title }}</p>

      <ul class="footer-ul">
        <li *ngFor="let item of cnt.items; trackBy: trackByFn">
          <alliance-clubs-links [link]="item.url" [isInternal]="!!item.isInternal">
            {{ item.label }}
          </alliance-clubs-links>
        </li>
      </ul>
    </div>

    <!-- TODO: UNCOMMENTED WHEN SUBSCRIBE IS DONE -->
    <!--  EMAIL SUBSCRIBE  -->
    <!--    <div class="footer-email-subscribe santa-pl-20">-->
    <!--      <p class="santa-typo-secondary-bold santa-mb-20">{{ translations.subscribe.title | translate }}</p>-->

    <!--      <div class="santa-bg-white">-->
    <!--        <santa-input-->
    <!--          label="{{ translations.subscribe.enterEmail | translate }}"-->
    <!--          [formControl]="emailControl"-->
    <!--          [type]="'email'"-->
    <!--          [autocomplete]="'email'"-->
    <!--        ></santa-input>-->
    <!--      </div>-->

    <!--      <div class="santa-flex santa-flex-row santa-my-20">-->
    <!--        <santa-checkbox [formControl]="subscribeForSeeker" class="santa-typo-additional">{{-->
    <!--          translations.subscribe.forSeeker | translate-->
    <!--        }}</santa-checkbox>-->
    <!--        <santa-checkbox [formControl]="subscribeForEmployer" class="santa-typo-additional santa-ml-20">-->
    <!--          {{ translations.subscribe.forEmployer | translate }}-->
    <!--        </santa-checkbox>-->
    <!--      </div>-->

    <!--      <div>-->
    <!--        <santa-button [fullWidth]="true">-->
    <!--          <alliance-icon [name]="IconEmailClosedEMailAddress" [inheritColor]="true"></alliance-icon>-->
    <!--          <span class="santa-ml-10 santa-typo-regular-bold">{{ translations.subscribe.buttonLabel | translate }}</span>-->
    <!--        </santa-button>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

  <!-- BOTTOM CONTENT -->
  <div class="footer-bottom-content">
    <!--  BUDNI LOGO  -->
    <div class="santa-flex santa-flex-col">
      <div class="santa-flex">
        <alliance-clubs-logo></alliance-clubs-logo>
      </div>
      <!--   TODO: FONT STYLES   -->
      <p class="santa-mt-10" style="font-size: 8px">{{ translations.budniDescription | translate }}</p>
    </div>

    <!--  ROBOTA LOGO  -->
    <div class="santa-flex santa-flex-col santa-ml-80">
      <a [href]="mainSiteUrl" target="_blank" class="santa-flex santa-items-center">
        <img height="30" width="auto" loading="lazy" src="https://images.cf-rabota.com.ua/2017/12/logos/logo-symbol-red.svg" alt="robota.ua" />
        <img class="santa-pl-10" height="15" width="auto" loading="lazy" src="https://images.cf-rabota.com.ua/2016/11/logo-text-none-padding.svg" alt="robota.ua" />
      </a>
      <!--   TODO: FONT STYLES   -->
      <p class="santa-mt-10" style="font-size: 8px">{{ translations.robotaDescription | translate }}</p>
    </div>

    <!--   TODO: FONT STYLES   -->
    <div class="santa-ml-auto santa-text-right santa-pl-20" style="max-width: 330px">
      <p class="santa-mb-10" style="font-size: 8px">{{ translations.terms.title | translate }}</p>
      <p style="font-size: 8px">{{ translations.terms.text | translate }}</p>
    </div>
  </div>
</div>
