import { ModuleWithProviders, NgModule, Type } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AbstractSeoDictionaryService } from './services/abstract-seo-dictionary.service'

@NgModule({
  imports: [CommonModule]
})
export class SharedSeoModule {
  public static forRoot<K>(dictionary: Type<AbstractSeoDictionaryService<K>>): ModuleWithProviders<SharedSeoModule> {
    return {
      ngModule: SharedSeoModule,
      providers: [{ provide: AbstractSeoDictionaryService, useClass: dictionary }]
    }
  }
}
