import { ChangeDetectionStrategy, Component } from '@angular/core'

import { FooterBaseComponent } from '../base/base.component'

@Component({
  selector: 'alliance-clubs-footer-desktop-component',
  templateUrl: './desktop.component.html',
  styleUrls: ['../base/base.component.tw.css', './desktop.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterDesktopComponent extends FooterBaseComponent {}
