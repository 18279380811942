import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'santa-indeterminate-progress-bar',
  templateUrl: './indeterminate-progress-bar.component.html',
  styleUrls: ['./indeterminate-progress-bar.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndeterminateProgressBarComponent {
  @Input() public useProgressBar = true

  public readonly bufferValue = 75
}
