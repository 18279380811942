import { ChangeDetectionStrategy, Component } from '@angular/core'

import { IconCross } from '@alliance/shared/icons'
import { LocalStorage } from '@alliance/shared/storage'
import { RxStateComponent } from '@alliance/shared/models'
import { Translations } from '../../localization/translations'

@Component({
  selector: 'alliance-clubs-banners-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.tw.css', './about-responsive.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutComponent extends RxStateComponent<{
  hideBudniBannerAbout: boolean
}> {
  public state = this.connectViewState(['hideBudniBannerAbout'])
  public budniSrc = 'https://images.cf-rabota.com.ua/clubs/banners/budni.svg'
  public readonly IconCross = IconCross.name
  public newsPostLink = '/news/onlayn-zhurnali-the-point-ta-prohr-perezapuskayutsya-u-formati-mediplatformi-budni'

  public constructor(private storageService: LocalStorage, public translations: Translations) {
    super()

    this.initState({
      hideBudniBannerAbout: false
    })
  }

  public close(): void {
    this.set({ hideBudniBannerAbout: true })
    this.storageService.setItem('hide_budni_banner_about', 'true')
  }
}
