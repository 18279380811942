import { Injectable } from '@angular/core'
import { BreadcrumbList } from 'schema-dts'
import { SeoParamsResponseModel } from '@alliance/shared/seo'
import { BudniRubricsPageModel } from '../models/budni-rubrics-page.model'
import { BudniBaseDictionaryService } from './budni-base-dictionary.service'

@Injectable({
  providedIn: 'root'
})
export class BudniRubricsPageDictionaryService extends BudniBaseDictionaryService {
  public getSeoParams(params?: BudniRubricsPageModel): SeoParamsResponseModel {
    return {
      title: this.translationService.translate(this.translations.budni.rubricPageTitle, { rubricName: params?.name ?? '' }),
      description: this.translationService.translate(this.translations.budni.rubricPageDescription, {
        rubricName: params?.name ?? ''
      }),
      h1: [params?.name ?? ''],
      canonicalUrl: this.helpersService.createURL(this.getBaseUrl(), params?.engName ?? ''),
      hrefLang: [],
      alternateUrl: '',
      noIndexNoFollow: false,
      jsonLd: this.getJsonLd(params)
    }
  }

  private getJsonLd(params?: BudniRubricsPageModel): string {
    return this.helpersService.createJsonLd<BreadcrumbList>({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: this.translationService.translate(this.translations.budni.breadcrumbs.home),
          item: this.helpersService.createURL(this.getBaseUrl())
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: params?.name ?? '',
          item: this.helpersService.createURL(this.getBaseUrl(), params?.engName ?? '')
        }
      ]
    })
  }
}
