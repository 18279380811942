import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core'

import { ClubTopic, PostsRubricsListService } from '@alliance/clubs/domain'
import { RoutingService } from '@alliance/clubs/utils'

import { RxStateComponent } from '@alliance/shared/models'
import { SkeletonClubsRubricsList } from '@alliance/shared/icons'
import { Environment } from '@alliance/shared/environment'

@Component({
  selector: 'alliance-clubs-sidebar-rubrics-list',
  templateUrl: './rubrics-list.component.html',
  styleUrls: ['./rubrics-list.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarRubricsListComponent extends RxStateComponent<{
  list: ClubTopic[] | null
}> {
  @Output() public rubricClicked = new EventEmitter<void>()

  public state = this.connectViewState(['list'])
  public readonly SkeletonClubsRubricsList = SkeletonClubsRubricsList
  public rubricsAWSDirectories = { icons: '/topic/icons/' }

  public constructor(private postsRubricsListService: PostsRubricsListService, public env: Environment, private routingService: RoutingService) {
    super()

    this.initState({
      list: this.postsRubricsListService.getPostsRubricsList$()
    })
  }

  public emitCloseSidebar(): void {
    this.rubricClicked.emit()
  }

  public getRubricPageRouterLink(rubric: ClubTopic): string {
    return this.routingService.getRubricPageRouterLink({ rubricName: rubric.engname })
  }
  public trackByFn(index: number): number {
    return index
  }
}
