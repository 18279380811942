import { ROBOTA_UA } from '@alliance/shared/constants'

export default {
  navigation: {
    seeker: `${ROBOTA_UA} для пошукачів`,
    employer: `${ROBOTA_UA} для роботодавця`,
    mediakit: 'Реклама',
    about: 'Про нас'
  }
}
