import { UrlMatchResult, UrlSegment } from '@angular/router'
import { CountryCodesEnum } from '../models/language-codes-enum'

export const languageMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  if (segments?.[0]?.path === CountryCodesEnum.UA) {
    return {
      consumed: [segments[0]],
      posParams: { lang: segments[0] }
    }
  }

  return {
    consumed: []
  }
}

export const languageMatcherDefaultUa = (segments: UrlSegment[]): UrlMatchResult | null => {
  /// If old urls stay with ua to prevent 404
  if (segments?.[0]?.path === CountryCodesEnum.UA) {
    return {
      consumed: [segments[0]],
      posParams: { lang: segments[0] }
    }
  }

  if (segments?.[0]?.path === CountryCodesEnum.RU) {
    return {
      consumed: [segments[0]],
      posParams: { lang: segments[0] }
    }
  }

  return {
    consumed: []
  }
}
